import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';


import signinImage from '../assets/signup.jpg';

const cookies = new Cookies();

const initialState = {
    fullName: '',
    username: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    avatarURL: '',
}

const Auth = () => {
    const [form, setForm] = useState(initialState);
    const [isSignup, setIsSignup] = useState(false);

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { username, password, phoneNumber, avatarURL } = form;

        //const URL = 'https://restaurant-message-center-426fe9c5ec86.herokuapp.com/auth'; //production heroku
        //const URL = 'https://staging-message-center-41f18c4a9934.herokuapp.com/auth'; //staging heroku
        //const URL = 'https://c891-2600-1700-5d81-abf-a1b8-9f92-8de0-fa0c.ngrok-free.app/auth'; //ngrok to local
        const URL = process.env.REACT_APP_AUTH_SERVER_URL + '/auth';

        const { data: { token, userId, hashedPassword, fullName } } = await axios.post(`${URL}/${isSignup ? 'signup' : 'login'}`, {
            username, password, fullName: form.fullName, phoneNumber, avatarURL, 
        });

        cookies.set('token', token);
        cookies.set('username', username);
        cookies.set('fullName', fullName);
        cookies.set('userId', userId);

        if (isSignup) {
            cookies.set('phoneNumber', phoneNumber);
            cookies.set('avatarURL', avatarURL);
            cookies.set('hashedPassword', hashedPassword);
        }

        window.location.reload();
    }

    const switchMode = () => {
        setIsSignup((prevIsSignup) => !prevIsSignup);
    }

    return (
        <div className="auth__form-container">
            <div className="auth__form-content">
                <div className="auth__form-title">
                    Superhero Hospitality
                </div>
                <div className="auth__form-container_fields">
                    <div className="auth__form-container_fields-content">
                        <p>{isSignup}</p>
                        <form onSubmit={handleSubmit}>
                            {isSignup && (
                                <div className="auth__form-container_fields-content_input">
                                    <input
                                        name="fullName"
                                        type="text"
                                        placeholder="Full Name"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            )}
                            <div className="auth__form-container_fields-content_input">
                                <input
                                    name="username"
                                    type="text"
                                    placeholder="Username"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            {isSignup && (
                                <div className="auth__form-container_fields-content_input">
                                    <input
                                        name="phoneNumber"
                                        type="text"
                                        placeholder="Phone Number"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            )}
                            {isSignup && (
                                <div className="auth__form-container_fields-content_input">
                                    <input
                                        name="avatarURL"
                                        type="text"
                                        placeholder="Avatar URL"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            )}
                            <div className="auth__form-container_fields-content_input">
                                <input
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            {isSignup && (
                                <div className="auth__form-container_fields-content_input">
                                    <input
                                        name="confirmPassword"
                                        type="password"
                                        placeholder="Confirm Password"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            )}
                            <div className="auth__form-container_fields-content_button">
                                <button>{isSignup ? "Sign Up" : "Sign In"}</button>
                            </div>
                        </form>
                        {/*<div className="auth__form-container_fields-account">
                            <p>
                                {isSignup
                                    ? "Already have an account? "
                                    : "Don't have an account? "
                                }
                                <span onClick={switchMode}>
                                    {isSignup ? 'Sign In' : 'Sign Up'}
                                </span>
                            </p>
                            </div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth
