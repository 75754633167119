import React, { useState, useEffect } from 'react';
import { ChannelList, InfiniteScroll, InfiniteScrollProps, useChatContext, ChannelSearchFunctionParams } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import Cookies from 'universal-cookie';

import { ChannelSearch, TeamChannelList, TeamChannelPreview, UserInfo } from './';
import LogoutIcon from '../assets/logout.png';
import RightArrow from '../assets/right-arrow.png';
import LeftArrow from '../assets/left-arrow.png';
import { set } from 'firebase/database';



const customersUI = require('./customerUI.json');
const customer = customersUI.customers[customersUI.customerChoice];

//const logoChoice = 'logo';
const logoChoice = customer.logo;
const campaignSection = customer.campaignSection;
const customerOutreachSection = customer.customerOutreachSection;

const SuperheroIcon = require(`../assets/${logoChoice}.png`);


const apiKey = process.env.REACT_APP_STREAM_API_KEY;
const streamClient = StreamChat.getInstance(apiKey); //staging Stream app
const cookies = new Cookies();

{/*const SideBar = ({ logout }) => (
    <div className="channel-list__sidebar">
        <div className="channel-list__sidebar__icon1">
            <div className="icon1__inner">
                <img src={SuperheroIcon} alt="Hospital" width="30" />
            </div>
        </div>
        <div className="channel-list__sidebar__icon2">
            <div className="icon1__inner" onClick={logout}>
                <img src={LogoutIcon} alt="Logout" width="30" />
            </div>
        </div>
    </div>
);*/}

const Toggle = ({ handleToggle }) => {
    const { client } = useChatContext();
    const currentUser = client.user;

    // Set the initial state of toggleNumber based on user's messageToggle variable or 5 if not set
    const initialToggleNumber = currentUser?.messageToggle || 5;
    const [toggleNumber, setToggleNumber] = useState(initialToggleNumber);

    const handleToggleClick = async (increment) => {
        const updatedToggleNumber = toggleNumber + increment;

        // Ensure that the updatedToggleNumber does not go below 0
        const newToggleNumber = Math.max(0, updatedToggleNumber);

        setToggleNumber(newToggleNumber);

        try {
            // Update the "messageToggle" value for the user
            await updateMessageToggle(currentUser.id, newToggleNumber);
        } catch (error) {
            console.error('Error occurred while updating messageToggle:', error);
        }
    };

    const updateMessageToggle = async (userId, messageToggleValue) => {
        try {
            // Make a partial update call to set the custom variable "messageToggle"
            const update = {
                id: userId,
                set: {
                    messageToggle: messageToggleValue,
                },
            };

            // Perform the partial update for the user
            const response = await streamClient.partialUpdateUser(update);

            console.log(`Custom user variable "messageToggle" updated for user with id: ${userId}`);
            console.log('Updated user object:', response.user);
        } catch (error) {
            console.error('Error occurred while setting custom user variable:', error);
            throw error;
        }
    };

    const getMessageText = () => {
        if (toggleNumber === 1) {
            return 'Message Per Day';
        }
        return 'Messages Per Day';
    };

    return (
        <div className="toggle-number">
            <button className="toggle-number__button" onClick={() => handleToggleClick(-1)}>
                <img src={LeftArrow} alt="Left Arrow" width="20" />
            </button>

            <span>{toggleNumber}</span>

            <button className="toggle-number__button" onClick={() => handleToggleClick(1)}>
                <img src={RightArrow} alt="Right Arrow" width="20" />
            </button>

            <span className="toggle-number__text">{getMessageText()}</span>
        </div>
    );
};

const MessageType = ({ onMessageTypeClick }) => {
    const [activeButton, setActiveButton] = useState('attention');
    const [isThemesExpanded, setIsThemesExpanded] = useState(false); // State to track the Themes section expansion
    const { client } = useChatContext();
    const userId = {
        name: "Magi Z",
        status: "Version 3.5",
        lastLogin: "Tomorrow"
    };

    const themes = [
        'Food',
        'Service',
        'Full Experience',
        'Desire to Return',
        'Request',
        'Other'
    ];


    const handleButtonClick = (type) => {
        setActiveButton(type);
        onMessageTypeClick(type);
    };

    const handleThemesButtonClick = () => {
        // Toggle the Themes section's expansion state
        setIsThemesExpanded(!isThemesExpanded);
    };

    const handleLinkButtonClick = () => {
        window.open('https://docs.google.com/spreadsheets/d/e/2PACX-1vRhseXUjTt5pHsotwrf7DhsGyNnfam0r7rwc9GfQtE1G5mdsCCnrK-CDXMtwB0flRDCaJ6bqpuWeyYx/pubhtml?gid=1240961617&single=true', '_blank'); // lace with your desired link
    };

    return (
        <div>
            <div className="filter-categories__header">Message Filters</div>
            <div className="channel-list__buttons">
                <button
                    className={`channel-list__button${activeButton === 'attention' ? ' active' : ''}`}
                    onClick={() => handleButtonClick('attention')}
                >
                    Attention Needed
                </button>
                <button
                    className={`channel-list__button${activeButton === 'replied' ? ' active' : ''}`}
                    onClick={() => handleButtonClick('replied')}
                >
                    Replied
                </button>
                <button
                    className={`channel-list__button${activeButton === 'all' ? ' active' : ''}`}
                    onClick={() => handleButtonClick('all')}
                >
                    All Messages
                </button>
                <button
                    className={`channel-list__button${activeButton === 'starred' ? ' active' : ''}`}
                    onClick={() => handleButtonClick('starred')}
                >
                    Starred Conversations
                </button>
            </div>
            <div className="filter-categories__header">Pending Thank-Yous</div>
            <div className="channel-list__buttons">
                <button
                    className={`channel-list__button${activeButton === 'sending_10am' ? ' active' : ''}`}
                    onClick={() => handleButtonClick('sending_10am')}
                >
                    Queued
                </button>
                {/*<button
                    className={`channel-list__button${activeButton === 'return_guests' ? ' active' : ''}`}
                    onClick={() => handleButtonClick('return_guests')}
                >
                    Return Guests
                </button>*/}
            </div>

            <div>
            {customerOutreachSection && (
                <div>
                <div className="filter-categories__header">Customer Outreach</div>
                
                    <div>
                        <div className="channel-list__buttons">
                            <button
                                className={`channel-list__button${activeButton === '8_nights' ? ' active' : ''}`}
                                onClick={() => handleButtonClick('8_nights')}
                            >
                                8 Nights
                            </button>
                        </div>
                        <div className="channel-list__buttons">
                            <button
                                className={`channel-list__button${activeButton === 'the_crow' ? ' active' : ''}`}
                                onClick={() => handleButtonClick('the_crow')}
                            >
                                The Crow
                            </button>
                        </div>
                    </div>
    
            </div>)}</div>
            <div>
                <div className="filter-categories__header">Superhero Support</div>
                <div>
                    <div className="channel-list__buttons">
                        <button
                            className={`channel-list__button${activeButton === 'support' ? ' active' : ''}`}
                            onClick={() => handleButtonClick('support')}
                        >
                            Support Channel
                        </button>
                    </div>
                </div>
            </div>
            {/*<div className="filter-categories__header">Stats</div>
            <div className="channel-list__buttons">
                <button
                    className={`channel-list__button${activeButton === 'open_link' ? ' active' : ''}`}
                    onClick={() => handleLinkButtonClick()}
                >
                    Dashboard
                </button>
            </div>*/}
            {/*<div className="filter-categories__header">Themes</div>
            <div className="channel-list__buttons">
                <button
                    className={`channel-list__button${activeButton === 'open_link' ? ' active' : ''}`}
                    onClick={() => handleThemesButtonClick()}
                >
                    Categories
                </button>
                {isThemesExpanded && (
                    // Render the 6 theme buttons if the Themes section is expanded
                    themes.map((theme, index) => (
                        <button
                            key={index}
                            className={`channel-list__button__theme${activeButton === theme ? ' active' : ''}`}
                            onClick={() => handleButtonClick(theme)}
                        >
                            {theme}
                        </button>
                    ))
                )}
            </div>
            <div className="filter-categories__header">User Info:</div>
            <div className="channel-list__buttons">
                <UserInfo userId={userId}></UserInfo>
                    </div>*/}
        </div>
    );
};

const CompanyHeader = () => (
    <div className="channel-list__header">
        <p className="channel-list__header__text">SuperHero Hospitality</p>
    </div>
);

const WelcomeMessage = () => {
    const { client } = useChatContext();
    const fullName = client.user?.fullName || 'Guest'; // Assuming the full name is stored in the 'fullName' field, provide a fallback value if necessary

    return (
        <div className="welcome-message">
            <p>Welcome, {fullName}</p>
            <div className="logo-container">
                <img className="logo-image" src={SuperheroIcon} alt="Logo"  />
            </div>
        </div>
    );
};

const customChannelTeamFilter = (channels) => {
    return channels.filter((channel) => channel.type === 'team');
};

const ChannelListContent = ({
    setIsCreating,
    setCreateType,
    setIsEditing,
}) => {
    const [selectedMessageType, setSelectedMessageType] = useState('all');
    const [categoryQuery, setCateoryQuery] = useState('all');
    const [outreachQuery, setOutreachQuery] = useState('all');
    const [starredQuery, setStarredQuery] = useState('all');
    const [attentionQuery, setAttentionQuery] = useState('all');
    const [supportQuery, setSupportQuery] = useState('all');
    const [showToggle, setShowToggle] = useState(false); // New state variable
    const { client } = useChatContext();

    const init = () => {
        // Similar to starting in 'all'
        console.log("Init in channel thingy");
        setShowToggle(false); // Hide the Toggle component
        //setCateoryQuery({ $eq: 'action_needed' });
        setCateoryQuery({});
        setOutreachQuery({});
        setStarredQuery({});
        setAttentionQuery(true);
        setSupportQuery({});
    }

    useEffect(() => {
        init();
    }, []);

    const handleMessageTypeClick = (messageType) => {
        setSelectedMessageType(messageType);

        let tags = '';

        switch (messageType) {
            case 'action_needed':
                tags = 'action_needed';
                setShowToggle(false); // Hide the Toggle component
                setCateoryQuery({ $eq: 'action_needed' });
                setOutreachQuery({});
                setStarredQuery({});
                setAttentionQuery({});
                setSupportQuery({});
                break;
            case 'attention':
                tags = '';
                setShowToggle(true); // Show the Toggle component
                setCateoryQuery({});
                setOutreachQuery({});
                setStarredQuery({});
                setAttentionQuery(true);
                setSupportQuery({});
                break;
            case 'replied':
                tags = 'replied';
                setShowToggle(false); // Hide the Toggle component
                setCateoryQuery({ $eq: 'action_needed' });
                setOutreachQuery({});
                setStarredQuery({});
                setAttentionQuery(false);
                setSupportQuery({});
                break;
            case 'all':
                tags = '';
                setShowToggle(false); // Hide the Toggle component
                setCateoryQuery({ $in: ['starred', 'action_needed', 'responded_to', 'action', 'processed_by_10am_filter_script', 'notsending10AM', 'cron_processed'] });
                //setCateoryQuery({});
                setOutreachQuery({});
                setStarredQuery({});
                setAttentionQuery({});
                setSupportQuery({});
                break;
            case 'sending_10am':
                tags = 'sending_10am';
                setShowToggle(true); // Show the Toggle component
                setCateoryQuery({ $eq: 'sending_10am' });
                setOutreachQuery({});
                setStarredQuery({});
                setAttentionQuery({});
                setSupportQuery({});
                break;
            case '8_nights':
                tags = '';
                setShowToggle(true); // Show the Toggle component
                setCateoryQuery({});
                setOutreachQuery({ $eq: '8_nights' });
                setStarredQuery({});
                setAttentionQuery({});
                setSupportQuery({});
                break;
            case 'the_crow':
                tags = '';
                setShowToggle(true); // Show the Toggle component
                setCateoryQuery({});
                setOutreachQuery({ $eq: 'the_crow' });
                setStarredQuery({});
                setAttentionQuery({});
                setSupportQuery({});
                break;
            case 'starred':
                tags = '';
                setShowToggle(true); // Show the Toggle component
                setCateoryQuery({});
                setOutreachQuery({});
                setStarredQuery(true);
                setAttentionQuery({});
                setSupportQuery({});
                break;
            case 'support':
                tags = '';
                setShowToggle(true); // Show the Toggle component
                setCateoryQuery({});
                setOutreachQuery({});
                setStarredQuery({});
                setAttentionQuery({});
                setSupportQuery(true);
                break;
            default:
                break;
        }

        // Use the 'tags' value to filter channels or update the channel list
        // based on the selected message type.
    };

    const customChannelMessagingFilter = (channels) => {
        return channels;

        if (selectedMessageType === 'all') {
            return channels.filter((channel) => channel.data.category !== 'sending_10am');
        } else if (selectedMessageType === 'action_needed') {
            return channels.filter((channel) => channel.data.category === 'action_needed');
        } else if (selectedMessageType === 'sending_10am') {
            return channels.filter((channel) => channel.data.category === 'sending_10am');
        } else {
            return [];
        }
    };


    const filters = {
        members: { $in: [client.userID] },
        //category: {$eq : selectedMessageType}
        category: categoryQuery,
        outreachTag: outreachQuery,
        starred: starredQuery,
        attention: attentionQuery,
        support: supportQuery
    };

    const options = {
        limit: 40
    }

    const customSearchFunction = async (props, event, client) => {
        const { setResults, setSearching, setQuery } = props;
        const value = event.target.value;

        const filters = {
            id: value, //want this to be maybe created_by.fullName
        };

        setSearching(true);
        setQuery(value);
        const channels = await client.queryChannels(filters);
        setResults(channels);
        setSearching(false);
    };



    const Paginator = (props) => <InfiniteScroll {...props} threshold={50} />;

    return (
        <>
            {/*<SideBar logout={logout} />*/}
            <div className="channel-list__list__wrapper" >
                <div className="filter-categories">
                    <div>
                        <CompanyHeader />
                        <WelcomeMessage /> {/* Render WelcomeMessage component */}
                        <MessageType onMessageTypeClick={handleMessageTypeClick} />
                    </div>
                </div>
                <div className="channel-list__messages">
                    {/*{showToggle && <Toggle handleToggle={() => { }} />}*/} {/* Conditional rendering of the Toggle component */}
                    <ChannelList
                        filters={filters}
                        options={options}
                        Paginator={InfiniteScroll}
                        /*
                        additionalChannelSearchProps={{
                            searchFunction: (params, event) => {
                                return customSearchFunction(params, event, client);
                            },
                        }}
                        showChannelSearch
                         */
                        channelRenderFilterFn={customChannelMessagingFilter}
                        List={(listProps) => (
                            <TeamChannelList
                                {...listProps}
                                type="messaging"
                                isCreating={false}
                                setIsCreating={setIsCreating}
                                setCreateType={setCreateType}
                                setIsEditing={setIsEditing}
                            />
                        )}
                        Preview={(previewProps) => (
                            <TeamChannelPreview
                                {...previewProps}
                                setIsCreating={setIsCreating}
                                setIsEditing={setIsEditing}
                                type="messaging"
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};

const ChannelListContainer = ({
    setCreateType,
    setIsCreating,
    setIsEditing,
}) => {
    const [toggleContainer, setToggleContainer] = useState(false);

    return (
        <>
            <div className="channel-list__container">

                <ChannelListContent
                    setIsCreating={setIsCreating}
                    setCreateType={setCreateType}
                    setIsEditing={setIsEditing}
                />
            </div>

            <div
                className="channel-list__container-responsive"
                style={{
                    left: toggleContainer ? '0%' : '-89%',
                    backgroundColor: '#005fff',
                }}
            >
                <div
                    className="channel-list__container-toggle"
                    onClick={() =>
                        setToggleContainer((prevToggleContainer) => !prevToggleContainer)
                    }
                ></div>
                <ChannelListContent
                    setIsCreating={setIsCreating}
                    setCreateType={setCreateType}
                    setIsEditing={setIsEditing}
                />
            </div>
        </>
    );
};

export default ChannelListContainer;