import React, { useState, useEffect } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, useChatContext } from 'stream-chat-react';
import Cookies from 'universal-cookie';
import LoopingGif from './assets/paperairplane.gif';
import ChatBubbles from './assets/chat.png';
import Reports from './assets/report.png';
import Campaigns from './assets/campaign.png';
import Profile from './assets/profile.png';
import Referral from './assets/referral.png';
import Logout from './assets/logout.png';
import Users from './assets/users.png';

import { ConversationType, ChannelListContainer, ChannelContainer, Auth } from './components';

// import 'stream-chat-react/dist/css/v2/index.css';
import 'stream-chat-react/dist/css/index.css';
import './App.css';
import { Widget } from '@typeform/embed-react'

const customersUI = require('./components/customerUI.json');
const customer = customersUI.customers[customersUI.customerChoice];
const reportsSection = customer.reportsSection;
const referralSection = customer.referralSection;
const referralURL = customer.referralURL;
const campaignSection = customer.campaignSection;
const usersSection = customer.usersSection;

const revenue = customer.revenueGraph;
const responseRate = customer.responseRateGraph;
const thankYous = customer.totalThankYousGraph;
const largeGraph = customer.largeGraph;

const cookies = new Cookies();

const apiKey = process.env.REACT_APP_STREAM_API_KEY;
const authToken = cookies.get('token');

const client = StreamChat.getInstance(apiKey);

if (authToken) {
  client.connectUser({
    id: cookies.get('userId'),
    name: cookies.get('username'),
    fullName: cookies.get('fullName'),
    image: cookies.get('avatarURL'),
    hashedPassword: cookies.get('hashedPassword'),
    phoneNumber: cookies.get('phoneNumber'),
  }, authToken);
}

const Banner = ({ activeButton, setActiveButton }) => {
  const logout = () => {
    cookies.remove('token');
    cookies.remove('userId');
    cookies.remove('username');
    cookies.remove('fullName');
    cookies.remove('avatarURL');
    cookies.remove('hashedPassword');
    cookies.remove('phoneNumber');

    window.location.reload();
  };




  return (
    <div className="banner">
      {/* Top section with the first three buttons */}
      <div className="top-buttons">
        <button className={`banner_button${activeButton === 'Messages' ? ' active' : ''}`} onClick={() => setActiveButton('Messages')}>
          <img src={ChatBubbles} alt="Messages Icon" style={{ width: '20px', height: '20px' }} />
          <span className="banner_button_label"> Messages</span>
        </button>
        {campaignSection && (
          <button className={`banner_button${activeButton === 'Campaigns' ? ' active' : ''}`} onClick={() => setActiveButton('Campaigns')}>
            <img src={Campaigns} alt="Campaigns Icon" style={{ width: '20px', height: '20px' }} />
            <span className="banner_button_label"> Campaigns</span>
          </button>
        )}
        {reportsSection && (
          <button className={`banner_button${activeButton === 'Reports' ? ' active' : ''}`} onClick={() => setActiveButton('Reports')}>
            <img src={Reports} alt="Reports Icon" style={{ width: '20px', height: '20px' }} />
            <span className="banner_button_label"> Reports</span>
          </button>
        )}
        {referralSection && (
          <button className="banner_button" onClick={() => setActiveButton('Referral')}>
            <img src={Referral} alt="Referral Icon" style={{ width: '20px', height: '20px' }} />
            <span className="banner_button_label">Referral</span>
          </button>
        )}
        {usersSection && (
          <button className="banner_button" onClick={() => setActiveButton('Users')}>
            <img src={Users} alt="Users Icon" style={{ width: '20px', height: '20px' }} />
            <span className="banner_button_label">Users</span>
          </button>
        )}
      </div>

      {/* Bottom section with the logout button */}
      <div className="bottom-buttons">
        <button className="banner_button_logout" onClick={logout}>
          <img src={Logout} alt="Messages Icon" style={{ width: '20px', height: '20px' }} />
          <span className="banner_button_label_logout"> Log Out</span>
        </button>
      </div>
    </div>
  );
};

const handleEmailButtonClick = () => {
  const emailSubject = 'Superhero Messaging';
  const emailBody = `Superhero Messaging is a proven digital hospitality solution that increases restaurant revenue through Thank You messages. 70% of first-time guests never return to the restaurant; our product drives that percentage down over time and increases customer loyalty by directly engaging reservation customers. With advanced data reporting, Superhero Messaging will reveal customer return revenue, messaging engagement, and earnings against labor minutes so that you can review our value with confidence. Schedule a 10 minute demo to learn more about how Superhero Messaging empowers your restaurant.`;

  const email = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
  window.location.href = email;
};

const handleSMSButtonClick = () => {
  const iMessageBody = `Superhero Messaging is a proven digital hospitality solution that increases restaurant revenue through Thank You messages. 70% of first-time guests never return to the restaurant; our product drives that percentage down over time and increases customer loyalty by directly engaging reservation customers. With advanced data reporting, Superhero Messaging will reveal customer return revenue, messaging engagement, and earnings against labor minutes so that you can review our value with confidence. https://calendly.com/superherohospitality/demo Schedule a 10-minute demo</a> to learn more about how Superhero Messaging empowers your restaurant.Superhero Messaging is a proven digital hospitality solution that increases restaurant revenue through Thank You messages. 70% of first-time guests never return to the restaurant; our product drives that percentage down over time and increases customer loyalty by directly engaging reservation customers. With advanced data reporting, Superhero Messaging will reveal customer return revenue, messaging engagement, and earnings against labor minutes so that you can review our value with confidence. Schedule a 10 minute demo to learn more about how Superhero Messaging empowers your restaurant.`;

  const iMessage = `sms:&body=${encodeURIComponent(iMessageBody)}`;
  window.location.href = iMessage;
};

const handleFacebookButtonClick = () => {
  const facebookMessage = 'Superhero Messaging is a proven digital hospitality solution that increases restaurant revenue through Thank You messages. 70% of first-time guests never return to the restaurant; our product drives that percentage down over time and increases customer loyalty by directly engaging reservation customers. With advanced data reporting, Superhero Messaging will reveal customer return revenue, messaging engagement, and earnings against labor minutes so that you can review our value with confidence. Schedule a 10 minute demo to learn more about how Superhero Messaging empowers your restaurant.';

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${encodeURIComponent(facebookMessage)}`;
  window.open(facebookShareUrl, '_blank');
};

const copyReferralCode = () => {
  const codeElement = document.getElementById("referral-code");
  const codeText = codeElement.textContent || codeElement.innerText;

  const tempTextArea = document.createElement("textarea");
  tempTextArea.value = codeText;

  document.body.appendChild(tempTextArea);
  tempTextArea.select();
  tempTextArea.setSelectionRange(0, 99999);

  document.execCommand("copy");

  document.body.removeChild(tempTextArea);

  // Update button text to "Code Copied!"
  const copyButton = document.getElementById("copy-button");
  if (copyButton) {
    copyButton.innerText = "Link Copied!";
    copyButton.disabled = true; // Optionally, disable the button after copying
  }

  // Reset button text after a delay (you can customize the delay)
  setTimeout(() => {
    if (copyButton) {
      copyButton.innerText = "Copy Code";
      copyButton.disabled = false; // Optionally, re-enable the button
    }
  }, 10000); // 2000 milliseconds (2 seconds) delay, adjust as needed

  //alert("Referral code copied to clipboard: " + codeText);
};

const WelcomeMessage = () => {
  const { client } = useChatContext();
  const fullName = client?.user?.fullName || 'Guest';

  return (
    <div className="welcome-message">
      <p>Welcome, {fullName}</p>
    </div>
  );
};

function CampaignForm() {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);
    // Add your form submission logic here
  }

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <div style={styles.formGroup}>
        <label style={styles.label}>Your Name:</label>
        <input
          type="text"
          name="username"
          value={inputs.username || ""}
          onChange={handleChange}
          style={styles.input}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Your Age:</label>
        <input
          type="number"
          name="age"
          value={inputs.age || ""}
          onChange={handleChange}
          style={styles.input}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Date Range:</label>
        <input
          type="date"
          name="startDate"
          value={inputs.startDate || ""}
          onChange={handleChange}
          style={styles.input}
        />
        <span style={styles.dateSeparator}> to </span>
        <input
          type="date"
          name="endDate"
          value={inputs.endDate || ""}
          onChange={handleChange}
          style={styles.input}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Keywords (Comma-separated):</label>
        <input
          type="text"
          name="keywords"
          value={inputs.keywords || ""}
          onChange={handleChange}
          style={styles.input}
        />
      </div>

      <div style={styles.formGroup}>
        <label style={styles.label}>Content:</label>
        <textarea
          name="content"
          value={inputs.content || ""}
          onChange={handleChange}
          style={{ ...styles.input, ...styles.textArea }}
        />
      </div>

      <button type="submit" style={styles.submitButton}>Submit</button>
    </form>
  )
}



const styles = {
  form: {
    maxWidth: '400px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
  },
  dateSeparator: {
    margin: '0 10px',
    color: '#555',
  },
  textArea: {
    resize: 'vertical',
    minHeight: '100px',
  },
  submitButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

const App = () => {
  const [activeButton, setActiveButton] = useState('Messages');
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [createType, setCreateType] = useState(''); // Define createType here
  const [showWindowSizeWarning, setShowWindowSizeWarning] = useState(false);
  const [showFirstReport, setShowFirstReport] = useState(true); // State to toggle between reports


  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 970) {
        setShowWindowSizeWarning(true);
      } else {
        setShowWindowSizeWarning(false);
      }
    };

    handleResize(); // Check window size initially
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if (!authToken) return <Auth />;

  if (showWindowSizeWarning) {
    return (
      <div className="warning-message-container">
        <div className="warning-message">
          <div className="overlay-text">Oops! Looks like it's a bit cramped in here. <br /> <br />Expaning your browser window will put things back in order. <br /><br /> -Superhero Messaging</div>
          <img src={LoopingGif} alt="Warning GIF" className="full-screen-gif" />
        </div>
      </div>
    );
  }

  return (

    <div className="app">
      <div className="page__wrapper">

        <Banner setActiveButton={setActiveButton} activeButton={activeButton} />

        <div className="sections__wrapper">
          {activeButton === 'Messages' && (
            <div className="app__wrapper">
              <Chat client={client} theme="messaging light">
                <ChannelListContainer
                  isCreating={isCreating}
                  setIsCreating={setIsCreating}
                  setCreateType={setCreateType}
                  setIsEditing={setIsEditing}
                  setActiveButton={setActiveButton} //passed in from ChannelListContainer component
                />
                <ChannelContainer
                  isCreating={isCreating}
                  setIsCreating={setIsCreating}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  createType={createType}
                />
              </Chat>
            </div>
          )}

          {activeButton === 'Campaigns' && (
            
            <div className="campaign-form">
              <iframe src="https://form.typeform.com/to/ChiU2zGQ?typeform-embed-id=48627201982827695&amp;typeform-embed=embed-widget" title="Campaign Request Form" width="100%" height="100%"></iframe>
            </div>
            
            
          )}

{activeButton === 'Users' && (
            
            <div className="campaign-form">
              <iframe src="https://form.typeform.com/to/cE3D12dq?typeform-embed-id=4811352421883893&amp;typeform-embed=embed-widget" title="Users Form" width="100%" height="100%"></iframe>
            </div>
            
            
          )}



          {activeButton === 'Reports' && (
            <div className="reports-container">
              {/* Toggle button to switch between reports 
                  <label className="switch">
                    <input type="checkbox" checked={showFirstReport} onChange={() => setShowFirstReport(!showFirstReport)} />
                    <span className="slider round"></span>
                  </label>*/}

              {/* Conditional rendering based on the toggle state */}
              {showFirstReport ? (
                /* First report view */
                <div className="first-report-view">
                  
                  <div className="iframe-row">
                    <iframe width="295" height="142" seamless frameborder="0" scrolling="no" src={revenue}></iframe>
                    <iframe width="295" height="142" seamless frameborder="0" scrolling="no" src={responseRate}></iframe>
                    <iframe width="295" height="142" seamless frameborder="0" scrolling="no" src={thankYous}></iframe>
                  </div>
              
                  <div className="full-width-iframe">
                    <iframe width="100%" height="100%" seamless frameborder="0" scrolling="no" src={largeGraph}></iframe>
                  </div>
                </div>
              ) : (
                /* Second report view */
                <div className="second-report-view">
                  <div className="iframe-row">
                    <iframe width="295" height="142" seamless frameborder="0" scrolling="no" src={responseRate}></iframe>
                    <iframe width="295" height="142" seamless frameborder="0" scrolling="no" src={revenue}></iframe>
                    <iframe width="295" height="142" seamless frameborder="0" scrolling="no" src={responseRate}></iframe>
                  </div>
                  <div className="full-width-iframe">
                    <iframe width="1147" height="409" seamless frameborder="0" scrolling="no" src={largeGraph}></iframe>
                  </div>
                </div>
              )}

            </div>
          )}
          {activeButton === 'Referral' && (
            <div className='page__wrapper'>
              {/*<div className="referral-section">
                <embed
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                    height: '-webkit-fill-available'
                  }}
                  src='https://embed.referral-factory.com/av3SwK'
                />

              </div>*/}

              < div className="referral-section">
                <div className="referral-main-title">
                  <h4 className="referral-title-text">
                    Your Referral Link:
                  </h4>
                  <p className="code" id="referral-code">
                    <a href={referralURL} target="_blank" rel="noopener noreferrer">
                      {referralURL}
                    </a>
                  </p>
                  <button class="referral-code-button" id="copy-button" onClick={copyReferralCode}>
                    Copy Link
                  </button>
                </div>

                <div className="referral-sub-section">
                  <div className="template">
                    <h4 className="referral-title-text">
                      Your template:
                    </h4>
                    <p className="template-text">
                      Superhero Messaging is a proven digital hospitality solution that increases restaurant revenue through Thank You messages. 70% of first-time guests never return to the restaurant; our product drives that percentage down over time and increases customer loyalty by directly engaging reservation customers. With advanced data reporting, Superhero Messaging will reveal customer return revenue, messaging engagement, and earnings against labor minutes so that you can review our value with confidence. <a href="https://calendly.com/superherohospitality/demo">Schedule a 10-minute demo</a> to learn more about how Superhero Messaging empowers your restaurant.
                    </p>

                  </div>
                  <div className="share">
                    <h4 className="referral-title-text">
                      Share to:
                    </h4>
                    <div className="referral-buttons">
                      <button className="referral-button" onClick={() => handleEmailButtonClick()}>Email</button>
                      <button className="referral-button" onClick={() => handleSMSButtonClick()}>iMessage</button>
                      <button className="referral-button" onClick={() => handleFacebookButtonClick()}>Facebook</button>
                    </div>
                  </div>
                </div>
                <div className="referral-sub-section">
                  <div className="referral-stats">
                    <h4 className="referral-title-text">
                      Referred Signups:
                    </h4>
                    <p className="stats">
                      0
                    </p>
                  </div>
                  <div className="referral-stats">
                    <h4 className="referral-title-text">
                      Referral Savings:
                    </h4>
                    <p className="stats">
                      $0
                    </p>
                  </div>
                </div>
              </div>
            </div>

          )}
          {activeButton === 'Profile' && (
            <div className="profile-section">
              <h2>Profile Information</h2>
              <div className="profile-details">
                <div className="profile-item">
                  <span className="item-label">Name:</span>
                  <span className="item-value">John Doe</span>
                </div>
                <div className="profile-item">
                  <span className="item-label">Email:</span>
                  <span className="item-value">johndoe@example.com</span>
                </div>
                <div className="profile-item">
                  <span className="item-label">Restaurant Name:</span>
                  <span className="item-value">Sleek Restaurant</span>
                </div>
                <div className="profile-item">
                  <span className="item-label">Phone Number:</span>
                  <span className="item-value">123-456-7890</span>
                </div>
                <div className="profile-item">
                  <span className="item-label">Email Notifications:</span>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
                {/* Add more profile details as needed */}
              </div>
            </div>
          )}

        </div>
      </div>
    </div >
  );
};

export default App;
